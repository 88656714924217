/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo } from "react"
import { Box, Button } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { LoadingCircles } from "./Circles"
import "./content.css"
import "./circles.css"
import { StyledChevronLeftIcon, StyledChevronRightIcon } from "./styles/Icons"
import { MinimumHeight, NavBarHeight, ContentSectionHeight, BottomCirclesHeight } from "./Constants"
import { useDebouncedCallback } from 'use-debounce'
import { ContentBoxMemo } from "./MemoableContentBox"
import { isChrome } from 'react-device-detect'

export const Content = (props: any) => {
  const content = props.content
  const isMobile = props.isMobile
  const configLength: number = content.length

  const theme = useTheme()
  const bulletRefsArr = useRef<(HTMLElement[] | [])>([])
  const contentRefsArr = useRef<(HTMLElement[] | [])>([])
  const contentHolderRef = useRef<(HTMLElement | null)>(null)

  // Not const since we want to be able to update without rerendering.
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0)
  const [hover, setHover] = useState(false)

  function recenter(contentAlso: boolean, index = activeItemIndex) {
    if ((window.scrollY > 0.8 * Math.max(window.innerHeight, MinimumHeight))) {
      if (contentAlso) {
        contentRefsArr.current[index].scrollIntoView({ behavior: 'auto', inline: "center" })
      }
      bulletRefsArr.current[index].scrollIntoView({ behavior: 'auto', inline: "center" })
    }
  }

  function handleNext(direction: number) {
    const newActiveIndex = activeItemIndex + direction
    setActiveItemIndex(newActiveIndex)
    recenter(true, newActiveIndex)
  }

  function onScroll() {
    if (contentHolderRef.current !== null && contentRefsArr.current !== null) {
      const contentHolderBounding = contentHolderRef.current.getBoundingClientRect()
      const allCurrentContent = contentRefsArr.current
      let index = 0
      for (const value of allCurrentContent) {
        if (value === null) {
          return
        }

        const positions = value.getBoundingClientRect()
        // Compare current items size vs approximated/less strict content holder.
        if (positions.left > contentHolderBounding.left * 0.95 &&
          positions.right < contentHolderBounding.right * 1.05) {
          recenter(false, index)
          setActiveItemIndex(index)
          break
        }
        index = index + 1
      }
    }
  }
  const debounceScroll = useDebouncedCallback(onScroll, 250)

  // When content has changed, scroll back to beginning.
  useEffect(() => {
    recenter(true, 0)
  }, [content])

  // TODO: Add types here.
  const circlesContent = useMemo(() => (
    content.map((config: any, index: any) => (
      <Box key={config.ContentTitle} sx={{ paddingX: "1em", display: "flex" }}
        ref={(element: HTMLElement) => { bulletRefsArr.current[index] = element }}
      >
        <LoadingCircles
          key={index}
          xAxis={"18"}
          yAxis={"18"}
          radius={"15"}
          color={theme.palette.brightModeText}
          secondaryColor={theme.palette.decoration.bright}
          itemNum={index}
          activeItemNum={activeItemIndex}
          length={configLength}
          callBack={(delta: number) => { handleNext(delta) }}
          hover={hover}
        />
      </Box>
    ))
  ), [content, bulletRefsArr, activeItemIndex, configLength, hover])

  const MemoedContentBox = useMemo(() => (< ContentBoxMemo props={{ content, contentRefsArr, isMobile, setHover }} />), [content, contentRefsArr, isMobile])

  const MemoedLeft = useMemo(() => (
    (isMobile !== true) && <Button
      aria-label={`See previous item.`}
      onClick={() => {
        handleNext(activeItemIndex !== 0 ? -1 : configLength - 1)
      }
      }>
      <StyledChevronLeftIcon sx={{ height: "100%" }} />
    </Button>
  ), [isMobile, activeItemIndex, configLength])
  const MemoedRight = useMemo(() => (
    (isMobile !== true) && < Button
      aria-label={`See next item`}
      onClick={() => {
        handleNext(activeItemIndex < configLength - 1 ? 1 : 1 - configLength)
      }
      }>
      <StyledChevronRightIcon sx={{ height: "100%" }} />
    </Button >
  ), [isMobile, activeItemIndex, configLength])

  // NOTE TO FUTURE SELF: The reason we are doing this manual calculation of heights, rather than using 100% everywhere is because of the images in the content. To be able to use fit, we need to have the parent be a known size (calculatable, not relative - therefore stuff like 100%, how we usually do stuff does not work). Previously tried a solution to programatically recalculate based on boxbounds, but it sucked when resizing/changing isMobile.
  // TODO: Update this 100vh to be greater of 100vh, minimum screen size.
  // const useableSize = Math.max(100vh, 800px)
  const MobileHeight = `calc(100vh - ${NavBarHeight}px - 6em - ${4 * ContentSectionHeight}px - ${BottomCirclesHeight}px)`
  const DesktopHeight = `calc(100vh - ${NavBarHeight}px - 6em - ${ContentSectionHeight}px - ${BottomCirclesHeight}px)`

  // TODO: Design content to not be goofy. Verify Safari works well too.
  // TODO: Add actual content. Pics and text and whatnot.
  return (
    <Box sx={{
      display: "flex",
      height: "100%",
      color: theme.palette.brightModeText
    }}>
      {MemoedLeft}
      <Box sx={{
        display: "flex",
        flex: 1,
        minWidth: "0",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box
          sx={{
            display: "flex",
            color: theme.palette.brightModeText,
            flexDirection: "column",
            height: "100%",
            width: "100%"
          }}>
          <Box
            className={"content"}
            ref={contentHolderRef}
            sx={{
              display: "flex",
              flex: 1,
              alignItems: isMobile === true ? "flex-start" : "center",
              flexDirection: "row",
              overflowX: "scroll",
              overscrollBehaviorX: "contain",
              scrollSnapType: "x mandatory",
              transform: "translate3d(0, 0, 0)",
              willChange: "scroll-position",
              // Repainting is ultra slow for large chrome screen specifically. Disable scroll.
              ...(isChrome && isMobile !== true && {
                overflow: "hidden"
              }),
              // In Mobile: 100% of the height - the 80px Top nav bar - 6em padding - 172px (4 * 43px sections) - 52px bottom circles.
              // Desktop: 100% of the height - 80px Top Nav Bar - 6em padding - 43px sections - 52px bottom circles.
              height: isMobile === true ? MobileHeight : DesktopHeight
            }}
            onScroll={debounceScroll}
          >
            {MemoedContentBox}
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `${BottomCirclesHeight}px`
          }}>
            <Box sx={{
              margin: "auto",
              maxWidth: "100%",
              overflowX: "scroll"
            }}>
              <Box
                className={"circles"}
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  paddingBottom: "0.5em"
                }}>
                {circlesContent}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {MemoedRight}
    </Box >
  )
}
