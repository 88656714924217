import React, { useState, useMemo } from "react"
import { Box, Button } from "@mui/material"
import { jobs } from "./config/jobs"
import { education } from "./config/education"
import { achievements } from "./config/achievements"
import { projects } from "./config/projects"
import { Content } from "./Content"
import { useTheme } from '@mui/material/styles'
import { ContentSectionHeight, NavBarHeight } from "./Constants"

export const SecondHalfScreen = (props: any) => {
  const isMobile = props.isMobile
  // TODO: Add types here to clean up.
  // TODO: Convert this to have the value be a function with the content it wants or something
  const sections = [
    { title: "jobs", config: jobs },
    { title: "education", config: education },
    { title: "achievements", config: achievements },
    { title: "projects", config: projects }
  ]
  const theme = useTheme()

  const [activeContent, setActiveContent] = useState<any>(sections[0])

  const content = useMemo(() => (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      flex: 1
    }}>
      <Content content={activeContent.config} isMobile={isMobile} />
    </Box>
  ), [activeContent, isMobile])

  return (

    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        backdropFilter: "blur(10px)",
        justifyContent: "center",
        border: `2px solid ${theme.palette.brightModeText}`,
        marginTop: `calc(${NavBarHeight}px)`,
        width: `calc(100% - 6em)`,
        height: `calc(100% - ${NavBarHeight}px - 6em)`
      }}>
      <Box sx={{
        width: `100%`,
        display: "flex",
        justifySelf: "flex-start",
        color: theme.palette.brightModeText,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: isMobile === true ? "column" : "row",
        height: isMobile === true ? `${4 * ContentSectionHeight}px` : `${ContentSectionHeight}px`
      }}>
        {sections.map((section: any, index: number) => (
          <Button
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            aria-label={`See section ${section.title}`}
            key={section.title}
            onClick={() => { setActiveContent(section) }}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "0.75em",
              width: "100%",
              color: theme.palette.brightModeText,
              borderBottom: `2px solid ${theme.palette.brightModeText}`,
              borderLeft: index !== 0 ? `2px solid ${theme.palette.brightModeText}` : "none",
              borderRadius: 0,
              backgroundColor: activeContent.title === section.title ? "#00BBDB" : "none",
              ":hover": {
                backgroundColor: activeContent.title === section.title ? "#00BBDB" : "none"

              }
            }}>
            &nbsp;{section.title.toUpperCase()}&nbsp;
          </Button>
        ))}
      </Box>
      {content}
    </Box>

  )
}
