import React from "react"
import { Box, IconButton, Typography } from "@mui/material"
import { zIndexDict } from "./config"

import { useTheme } from '@mui/material/styles'
import { StyledSettingsIcon } from "./styles/Icons"
import { SettingsPopper } from "./components/SettingsPopper"
import { ContactIcons } from "./components/ContactIcons"
import { DanielTitleNoRef } from "./styles/TitleStyle"
import { NavBarHeight } from "./Constants"
interface NavBarProps {
  titleVisible: boolean
  isMobile: boolean
}

export const NavBar = (props: NavBarProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const titleAlreadyInView = props.titleVisible

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((anchorEl != null) ? null : event.currentTarget)
  }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: `${NavBarHeight}px`,
      zIndex: zIndexDict.landingBlur,
      position: "fixed",
      backdropFilter: "blur(5px)",
      borderBottom: `2px solid ${theme.palette.brightModeText}`,
      justifyContent: "center"
    }}>
      {!titleAlreadyInView &&
        <Box sx={{
          position: "absolute",
          alignSelf: "center",
          left: "0%",
          marginLeft: "2vw"
        }}>
          <ContactIcons row={!(props.isMobile)} />
        </Box>
      }
      {/* TODO: Fix that this stays too long on up phase when scroll up and down really fast. */}
      {!titleAlreadyInView && <DanielTitleNoRef theme={theme} />}

      <Box
        sx={{
          position: "absolute",
          alignSelf: "center",
          right: "0%",
          color: theme.palette.brightModeText
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}>
          {(!(props.isMobile) || titleAlreadyInView) && (
            <Typography>
              Pattern Settings:
            </Typography>
          )}
          <IconButton
            sx={{ marginRight: "2vw" }}
            onClick={(e) => { handleClick(e) }}
            aria-label="See point settings"
          >
            <StyledSettingsIcon />
          </IconButton>
        </Box>

      </Box>
      <SettingsPopper open={open} anchorEl={anchorEl} />

    </Box >
  )
}
