import WebsiteNewSquare from "../assets/images/projects/WebsiteNewSquare.png"
import WebsiteOldLong from "../assets/images/projects/WebsiteOldLong.png"
import CalorieSquare from "../assets/images/projects/CalorieSquare.png"
import EmployeeSquare from "../assets/images/projects/EmployeeSquare.png"
import ChemSquare from "../assets/images/projects/ChemSquare.png"
import VideoDownloaderSquare from "../assets/images/projects/VideoDownloaderSquare.png"
import SExpressionSquare from "../assets/images/projects/SExpressionSquare.png"

export const projects = [{
  ContentTitle: "Personal Website",
  SubTitle: "React, TypeScript, CSS",
  ContentIntro: `This current website you are on. It is built using React and TypeScript. The background animation is done using an HTML5 Canvas and controlled through TypeScript.`,
  ContentText: ` I built this website as a new version of my previous website since I realized I had let the content of the old website go un-updated, and I no longer liked the design style I used.`,
  ContentImage: WebsiteNewSquare
},
{
  ContentTitle: "YouTube Video Downloader",
  SubTitle: "Python",
  ContentIntro: `An app built using Python and Tkinter. I listened to music on YouTube, and had nothing to listen to without internet so I built this app to download videos I liked so I could listen to them later.`,
  ContentText: ` This was primarily powered by API, which is no longer supported due to YouTube terms and conditions. The majority of my work was with styling and designing the project, which resulted in a final product I was pleased with.`,
  ContentImage: VideoDownloaderSquare
},
{
  ContentTitle: "S Expression Calculator",
  SubTitle: "Python",
  ContentIntro: `An S-Expression is a nested list like data structure. This calculator takes in S-expressions representing simple algebraic equations and computes their result.`,
  ContentText: ` Many computer science professionals create a calculator app, and this is mine. It is run from Command Line since I was primarily interested in calculations and code management when I created this project.`,
  ContentImage: SExpressionSquare
},
{
  ContentTitle: "Chem Draw Tool",
  SubTitle: "Python",
  ContentIntro: `Built using Python and Tkinter, this was a small app meant to help me takes notes for my highschool chemistry class. I found it difficult to make chemistry structure diagrams in Microsoft Word, and so developed this.`,
  ContentText: ` This app allows users to draw lines and connections to text inputs representing chemical particles (ex: Oxygen) and was reasonably useful for me during chemistry class.`,
  ContentImage: ChemSquare
},
{
  ContentTitle: "Old Website",
  SubTitle: "HTML, CSS, JavaScript",
  ContentIntro: `I created this website using HTML and CSS (with some additional JavaScript) before I had any job experience. The website performed well and served its purpose, but I have updated it to this website since I prefer this new design.`,
  ContentText: ` Overall, I am very pleased with having created this website - though it is not in use anymore - since it was an excellent learning experience. It is still available (but unmaintained) at old.danielbrestoiu.me for archive purposes.`,
  ContentImage: WebsiteOldLong
},
{
  ContentTitle: "Employee Management System",
  SubTitle: "Java",
  ContentIntro: `Built in Java, this Employee Management System enabled users to input and maintain information for their employees; including features such as pay, identification, etc.`,
  ContentText: ` This project was amongst my earlier projects, and is designed similarly to many 2000s era applications. At the time, I was unaware of DataBases and simply stored this data in a plain text file controlled by the application.`,
  ContentImage: EmployeeSquare
},
{
  ContentTitle: "Nutrition Tracker",
  SubTitle: "Swift",
  ContentIntro: `This project was my first introduction to Mobile Development and my first introduction to Swift. Built for a hackathon, the product was completed in under 24 hours on my own.`,
  ContentText: ` The scope of this app was rather simple, with a focus primarily on accepting and maintaining user input information and then displaying it across time. The objective was to aid in diet management, and I had thought it might be interesting to one day link this with my previous FraserHacks Hackathon 2nd Place project. It also served as my first exposure to working with databases.`,
  ContentImage: CalorieSquare
}
]
