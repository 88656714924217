import React, { useState } from "react"
import { Popper } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { BottomArrow } from "./Arrow"
import { zIndexDict } from "../config"
import { PopperContents } from "./PopperContent"

interface SettingsPopperProps {
  open: boolean
  anchorEl: HTMLElement | null
}

export function SettingsPopper(props: SettingsPopperProps) {
  const [arrowRef, setArrowRef] = useState(null)
  const [patternValue, setPatternValue] = useState<String>("4")

  const theme = useTheme()

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      sx={{
        background: theme.palette.brightModeText,
        zIndex: zIndexDict.overLandingBlur
      }}
      placement={"bottom-end"}
      disablePortal={true}
      modifiers={[
        {
          name: 'flip',
          enabled: false
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef
          }
        }
      ]}
    >
      <BottomArrow setArrowRef={setArrowRef} />
      <PopperContents patternValue={patternValue} setPatternValue={setPatternValue} />
    </Popper>
  )
}
