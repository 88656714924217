import React from "react"
import { Box } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { ContentBox } from "./ContentBox"

export const ContentBoxMemo = (props: any) => {
  const content = props.props.content
  const contentRefsArr = props.props.contentRefsArr
  const isMobile = props.props.isMobile
  const setHover = props.props.setHover

  const theme = useTheme()

  return (content.map((config: any, index: number) => {
    return (
      <Box
        key={config.ContentTitle}
        ref={(element: HTMLElement) => { contentRefsArr.current[index] = element }}
        sx={{
          scrollBehavior: "smooth",
          scrollSnapAlign: "center",
          scrollSnapStop: "always",
          justifyContent: "center",
          transform: "translate3d(0, 0, 0)",
          WebkitTransform: "translate3d(0, 0, 0)",
          willChange: "scroll-position",
          backfaceVisibility: "hidden",
          // If not mobile, update to these values.
          ...(isMobile !== true && {
            minWidth: "50%",
            maxWidth: "100%",
            position: "relative",
            padding: "0 10% 0 10%",
            height: "90%"
          }),
          ...(isMobile === true && {
            width: "100%",
            height: "98%",
            minWidth: "100%"
          })
        }}
      >
        <Box
          className={"ContentBorder"}
          sx={{
            height: "100%",
            border: `2px solid ${theme.palette.brightModeText}}`,
            display: "flex",
            flexDirection: "column",
            ...(isMobile === true && {
              width: `calc(100% - 4px)`
            }),
            "@media (min-width: 600px)": {
              "& .ContentBorder:hover": {
                boxShadow: `1em 1em 1em ${theme.palette.decoration.bright}`,
                transform: "scale(1.025)"
              }
            }
          }}
          onMouseEnter={() => { setHover(true) }}
          onMouseLeave={() => { setHover(false) }}
        >
          <Box sx={{
            height: "100%",
            overflow: "clip",
            display: "flex",
            flexDirection: "column",
            background: "black"
          }}
          >
            <ContentBox props={{ content: config, index, length: content.length, isMobile }} />
          </Box>
        </Box>
      </Box>
    )
  }
  )
  )
}
