import { styled } from '@mui/system'
import { ReactComponent as ResumeIcon } from "../assets/svgs/resume.svg"
import { LinkedIn, GitHub, Email, ArrowDropDown, Settings, Close, ChevronLeft, ChevronRight } from '@mui/icons-material'

export const IconStyleCSS = ({ theme }: { theme: any }) => ({
  [theme.breakpoints.down("tablet")]: {
    viewBox: "0 0 16px 16px",
    width: "16px",
    height: "16px"
  },
  [theme.breakpoints.between("tablet", "desktop")]: {
    viewBox: "0 0 24px 24px",
    width: "24px",
    height: "24px"
  },
  [theme.breakpoints.between("desktop", "large")]: {
    viewBox: "0 0 32px 32px",
    width: "32px",
    height: "32px"
  },
  [theme.breakpoints.up("large")]: {
    viewBox: "0 0 40px 40px",
    width: "40px",
    height: "40px"
  },
  fill: theme.palette.brightModeText
})

export const BigSettingsCSS = ({ theme }: { theme: any }) => ({
  [theme.breakpoints.down("desktop")]: {
    viewBox: "0 0 32px 32px",
    width: "32px",
    height: "32px"
  },
  [theme.breakpoints.up("desktop")]: {
    viewBox: "0 0 40px 40px",
    width: "40px",
    height: "40px"
  },
  fill: theme.palette.brightModeText
})

// Icons
export const StyledResumeIcon = styled(ResumeIcon)(IconStyleCSS)
export const StyledLinkedInIcon = styled(LinkedIn)(IconStyleCSS)
export const StyledGitHubIcon = styled(GitHub)(IconStyleCSS)
export const StyledEmailIcon = styled(Email)(IconStyleCSS)
export const StyledArrowDropDownIcon = styled(ArrowDropDown)(IconStyleCSS)
export const StyledCloseIcon = styled(Close)(IconStyleCSS)
export const StyledChevronLeftIcon = styled(ChevronLeft)(IconStyleCSS)
export const StyledChevronRightIcon = styled(ChevronRight)(IconStyleCSS)

// Changing this one specifically to be bigger.
export const StyledSettingsIcon = styled(Settings)(BigSettingsCSS)
