import React, { useState, useEffect, useRef, useMemo } from "react"
import { Box, Typography, Button } from "@mui/material"
import { zIndexDict } from "./config"
import { Points, newPoint, loop } from "./FillCanvas"
import "./animations.css"
import { NavBar } from "./NavBar"
import SettingsDialog from "./SettingsDialog"
import { useTheme } from '@mui/material/styles'
import { isScrolledIntoView } from "./utils"
import { DanielTitleWithRef } from "./styles/TitleStyle"
import { StyledArrowDropDownIcon } from "./styles/Icons"
import { ContactIcons } from "./components/ContactIcons"
import { PointContext } from "./Context"
import { Pattern4 } from "./patterns/Patterns"

import { MinimumHeight, NavBarHeight } from "./Constants"
import { SecondHalfScreen } from "./SecondHalf"
export interface Coordinates {
  x: number
  y: number
}

/** Canvas set to the size of the screen available */
export function FullScreenCanvas() {
  const [windowHeight, setWindowHeight] = useState(Math.max(window.innerHeight, MinimumHeight))
  const [windowWidth, setWindowWidth] = useState(document.body.clientWidth)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [showDialog, setShowDialog] = useState(false)
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 600)

  const [titleRef, setTitleRef] = useState<null | HTMLElement>(null)
  const [titleVisible, setTitleVisible] = useState(true)

  const points = useMemo(() => { return new Set<Points>() }, [])
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const tickRef = useRef(0)
  const theme = useTheme()

  const [sparkles, setSparkles] = useState(true)
  const [pointsState, setPointsState] = useState<any>({
    pointsPerClick: 1,
    pointsSet: points,
    sparkles: true,
    ctx: canvasRef.current?.getContext('2d')
  })

  function handleIsMobile() {
    if (document.body.clientWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  // Need to update width and height, used as numbers in calculations for positions and such.
  useEffect(() => {
    function handleWindowResize() {
      setWindowHeight(Math.max(window.innerHeight, MinimumHeight))
      setWindowWidth(document.body.clientWidth)
      handleIsMobile()
    }

    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  function handleScroll() {
    const position = window.scrollY
    setScrollPosition(position)
    setTitleVisible(isScrolledIntoView(titleRef) || position + 2 * NavBarHeight <= window.innerHeight / 2)
  }

  // Listener to keep track of scrolling. Use for Show More and moving title.
  useEffect(() => {
    // Add and remove window listener.
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  // Handle clicking to add a new point.
  const givenHeight = 2 * windowHeight
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')
    const coordinates = { x: event.pageX, y: event.pageY }
    if (ctx != null) {
      // Setting to 1000 and clicking a ton makes cool patterns.
      // TODO: Code clean up
      const numPointsPerClick = pointsState != null ? pointsState.pointsPerClick : 1
      const sparkles = pointsState != null ? pointsState.sparkles : true
      const pointsSet = pointsState != null ? pointsState.pointsSet : points

      for (let i = 0; i < numPointsPerClick; i++) {
        newPoint({ ctx, coordinates, points: pointsSet, sparkles })
      }
      ctx.stroke()
    }
  }

  /** Scroll down by the size of one use screen size. */
  function manuallyScrollPage() {
    window.scroll({
      top: windowHeight,
      left: 0,
      behavior: 'smooth'
    })
  }

  function handlePointStateSetting(pointsState: any) {
    setPointsState(pointsState)
    setSparkles(pointsState.sparkles)
  }

  useEffect(() => {
    if (pointsState.ctx == null) {
      const canvas = canvasRef.current
      const ctx = canvas?.getContext('2d')
      pointsState.ctx = ctx
      setPointsState(pointsState)
      Pattern4(pointsState)
    }

    const pointsSet = pointsState != null ? pointsState.pointsSet : points

    // Cancel previous animation frame in order to be able to update sparkles.
    window.cancelAnimationFrame(tickRef.current)
    tickRef.current = window.requestAnimationFrame(() => loop({ canvasRef, width: windowWidth, height: givenHeight, points: pointsSet, tickRef, sparkles }))

    // Upon completion, cancel all animation frames.
    return () => window.cancelAnimationFrame(tickRef.current)
  }, [sparkles, givenHeight, windowWidth, points, pointsState])

  return (
    <>
      <PointContext.Provider value={{ pointsState, setPointsState: handlePointStateSetting }}>
        <NavBar titleVisible={titleVisible} isMobile={isMobile} />
        <SettingsDialog show={showDialog} selfClose={() => setShowDialog(false)} />

        <canvas style={{
          zIndex: zIndexDict.landingBG,
          position: "absolute",
          width: "100%",
          height: givenHeight,
          backgroundColor: "black",
          scrollbarGutter: "stable"
        }}
          ref={canvasRef}
          width={windowWidth}
          height={givenHeight}
        />
        <div style={{ width: "100%", height: windowHeight }}>
          < Box
            onClick={handleClick}
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Box sx={{
              zIndex: zIndexDict.landingStyle,
              backdropFilter: "blur(10px)",
              color: "brightModeText",
              textAlign: "center",
              height: "fit-content",
              margin: "auto",
              animation: "fadeup 1s ease-in-out forwards",
              paddingX: "3em"
            }}
            >
              {titleVisible && <DanielTitleWithRef ref={setTitleRef} />}
              {titleVisible && <Typography>Click the background to place points.</Typography>}
              {titleVisible && <Typography>Click the settings icon to update pattern settings.</Typography>}
              {titleVisible && <Typography>Scroll down to learn more about me!</Typography>}
              {titleVisible && <ContactIcons row={titleVisible} />}
            </Box>
            <Box sx={{
              zIndex: zIndexDict.landingStyle,
              height: "60px",
              color: theme.palette.brightModeText,
              position: "fixed",
              bottom: 0
            }}
            >
              <Button aria-label="Scroll down to see more."
                className={scrollPosition <= 40 ? "fade-in" : "fade-out"}
                onClick={(event) => {
                  manuallyScrollPage()
                  event.stopPropagation()
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    userSelect: "none",
                    color: theme.palette.brightModeText
                  }}
                >
                  See More
                </Typography>
                <StyledArrowDropDownIcon
                  sx={{
                    marginTop: 0
                  }}
                />
              </Button>
            </Box>

          </Box >
        </div >

        <div style={{ width: "100%", height: windowHeight, userSelect: "none" }}>
          < Box
            onClick={handleClick}
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <SecondHalfScreen isMobile={isMobile} windowWidth={windowWidth} />
          </Box>
        </div>

      </PointContext.Provider >
    </>
  )
}
