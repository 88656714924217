import WaterlooSquare from "../assets/images/education/WaterlooSquare.png"
import SVFLong from "../assets/images/education/SVFLong.png"
import SATSquare from "../assets/images/education/SATSquare.png"

export const education = [{
  ContentTitle: "University of Waterloo",
  SubTitle: "Computer Science & Finance",
  ContentIntro: `Globally recognized as a top school for computer science & engineering degrees, the University of Waterloo places 2nd among global schools that produce the most frequently successful candidates in Silicon Valley.`,
  ContentText: ` I am in my third year (3B) of completing a double major in Computer Science and Finance, with an expected graduation date in May 2025. My cumulative average is 87%. Waterloo is known for it's world-class co-op program, and upon graduation I will have completed two years of full time work experience.`,
  ContentImage: WaterlooSquare
},
{
  ContentTitle: "Student Venture Fund",
  SubTitle: "Senior Associate",
  ContentIntro: `A million dollar fund investing on behalf of the University of Waterloo. Run by students, the fund invests real money into real startups and is part of angel investment groups MLA and GTAN.`,
  ContentText: ` Maple Leaf Angels (MLA) and Golden Triangle Angel Network (GTAN) are among Canada's leading angel investment groups. I joined the fund as an Analyst and have since become a Senior Associate responsible for the fund.`,
  ContentImage: SVFLong
},
{
  ContentTitle: "SAT",
  SubTitle: "Perfect Score",
  ContentIntro: `I received a perfect score of 1600/1600 on the SAT, placing me in the 99.9th percentile of test takers.`,
  ContentText: ` Of the 1.7 million annual test takers, I am one of approximately 1000 to achieve a perfect score.`,
  ContentImage: SATSquare
}
]
