import React from "react"
import { Box } from "@mui/material"
import { useTheme } from '@mui/material/styles'

export function BottomArrow(props: { setArrowRef: React.Dispatch<React.SetStateAction<null>> }) {
  const theme = useTheme()
  const setArrowRef: React.Dispatch<React.SetStateAction<null>> = props.setArrowRef
  return (
    <Box component="span" ref={setArrowRef} sx={
      {
        position: 'absolute',
        top: "-5px",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: `5px solid ${theme.palette.background.paper}`
      }} />
  )
}
