import React, { useEffect, useRef } from "react"
import { Box, Button } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { Circle, CircleOutlined } from '@mui/icons-material'
import "./circles.css"
import { useInView } from "react-intersection-observer"

// TODO: Type validation
export function LoadingCircles(props: any) {
  const theme = useTheme()
  const circleRef = useRef<HTMLElement>(null)
  const [ref, inView] = useInView({ threshold: 0.5 })
  const itemNumber: number = props.itemNum

  const CIRCLE_SIZE = 36
  const STROKE_SIZE = 6
  const BORDER_SIZE = 3
  const index: number = props.itemNum

  // Add an event listener to catch when the animation is done, and should trigger carousel movement.
  useEffect(() => {
    function handleEndOfAnimation() {
      props.callBack(props.itemNum < props.length - 1 ? 1 : 1 - props.length)
    }

    if (circleRef.current != null) {
      circleRef.current.addEventListener("animationend", handleEndOfAnimation, false)
    }

    return () => {
      window.removeEventListener('animationend', handleEndOfAnimation)
    }
  }, [circleRef, props])

  return (
    <>
      {(props.itemNum < props.activeItemNum) &&
        <Button
          aria-label={`See item ${itemNumber}`}
          onClick={() => { props.callBack(props.itemNum - props.activeItemNum) }}>
          <Circle sx={{ width: "32px", height: "32px", color: theme.palette.brightModeText }} />
          <Box sx={{ position: "absolute", right: "28px", bottom: "10px", color: "black" }}>
            {index + 1}
          </Box>
        </Button>
      }
      {(props.itemNum > props.activeItemNum) &&
        <Button
          aria-label={`See item ${itemNumber}`}
          onClick={() => { props.callBack(props.itemNum - props.activeItemNum) }}>
          <CircleOutlined sx={{ width: "32px", height: "32px", color: theme.palette.brightModeText }} />
          <Box sx={{ position: "absolute", right: "28px", bottom: "10px", color: theme.palette.brightModeText }}>
            {index + 1}
          </Box>
        </Button>}
      {props.itemNum === props.activeItemNum && (
        <Box className="outside-border bullet-class"
          ref={ref}
          sx={{
            border: `${BORDER_SIZE}px solid ${theme.palette.brightModeText}`,
            opacity: 1,
            transition: "opacity 0.5s ease-in-out",
            borderRadius: 16,
            width: `${CIRCLE_SIZE + 2 * BORDER_SIZE}px`,
            height: `${CIRCLE_SIZE + 2 * BORDER_SIZE}px`,
            boxSizing: "border-box"
          }}>
          <Box component={"span"} ref={circleRef}
            sx={{
              margin: 0,
              strokeWidth: STROKE_SIZE,
              strokeDasharray: 101,
              fill: "none",
              position: "relative"
            }}>
            <svg width={CIRCLE_SIZE} height={CIRCLE_SIZE}>
              <circle id="loading-circle" cx={props.xAxis} cy={props.yAxis} r={props.radius}
                stroke="1" style={{
                  stroke: theme.palette.decoration.bright,
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  animationPlayState: (props.hover === true || !inView) ? "paused" : "running"

                }} className={"circle-loading"} />
            </svg>
            <Box sx={{ position: "absolute", right: "14px", bottom: "14px", color: theme.palette.brightModeText }}>
              {index + 1}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
