import { newPoint, opts } from "../FillCanvas"
import type { Coordinates } from "../Canvas"

// TODO: Clean up code
export function Pattern1(pointsState: any) {
  const sparkles = pointsState.sparkles
  const pointsSet = pointsState.pointsSet
  const ctx = pointsState.ctx
  const rect = ctx.canvas.getBoundingClientRect()
  const width = rect.width
  const height = rect.height

  // Each movement is a hexagon. 50 in x on one, then another
  const horizontal = 1.5 * opts.distance * 5
  const vertical = opts.distance / 2 * Math.sqrt(3) * 5

  const initialX = width / 3
  const initialY = height / 3

  let coordinates: Coordinates = { x: initialX, y: initialY }

  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: initialX + horizontal, y: initialY + vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: initialX + horizontal, y: initialY - vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
}

/** Draw a bunch in the same X position, but above and below each other. */
export function Pattern2(pointsState: any) {
  const sparkles = pointsState.sparkles
  const pointsSet = pointsState.pointsSet
  const ctx = pointsState.ctx
  const rect = ctx.canvas.getBoundingClientRect()
  const width = rect.width
  const height = rect.height

  const horizontal = 2 * opts.distance
  const vertical = opts.distance / 2 * Math.sqrt(3)

  const initialX = width / 4
  const initialY = height / 4

  const altX = initialX + horizontal * 7.5

  let coordinates: Coordinates = { x: initialX, y: initialY }

  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: initialX, y: initialY + vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: altX, y: initialY + 2 * vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: altX, y: initialY + 3 * vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
}

export function Pattern3(pointsState: any) {
  const sparkles = pointsState.sparkles
  const pointsSet = pointsState.pointsSet
  const ctx = pointsState.ctx
  const rect = ctx.canvas.getBoundingClientRect()
  const width = rect.width
  const height = rect.height

  // Each movement is a hexagon. 50 in x on one, then another
  const horizontal = 1.5 * opts.distance * 5
  const vertical = opts.distance / 2 * Math.sqrt(3) * 5

  const initialX = width / 2
  const initialY = height / 3

  let coordinates: Coordinates = { x: initialX, y: initialY }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: initialX + horizontal, y: initialY }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: initialX, y: initialY - vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
  coordinates = { x: initialX + horizontal, y: initialY - vertical }
  for (let i = 0; i < 2000; i++) {
    newPoint({ ctx, coordinates, points: pointsSet, sparkles })
  }
}

export function Pattern4(pointsState: any) {
  const sparkles = pointsState.sparkles
  const pointsSet = pointsState.pointsSet
  const ctx = pointsState.ctx
  const rect = ctx.canvas.getBoundingClientRect()
  const width = rect.width
  const height = rect.height

  const initialX = width / 2
  const initialY = height / 2.5

  const coordinates: Coordinates = { x: initialX, y: initialY }

  // This actually continue running interval even during other Modes, however those have more than 7.5k...
  // TODO: Fix this bug. Stop adding new points if not this mode.
  const intervalID = setInterval(function () {
    if (pointsSet.size < 7500) {
      for (let i = 0; i < 10; i++) {
        newPoint({ ctx, coordinates, points: pointsSet, sparkles })
      }
    } else {
      clearInterval(intervalID)
    }
  }, 1)
  return intervalID
}

export function Pattern5(pointsState: any) {
  const sparkles = pointsState.sparkles
  const pointsSet = pointsState.pointsSet
  const ctx = pointsState.ctx
  const rect = ctx.canvas.getBoundingClientRect()
  const width = rect.width
  const height = rect.height

  const initialX = width / 2
  const initialY = height / 2.5

  // This actually continue running interval even during other Modes, however those have more than 7.5k...
  const intervalID = setInterval(function () {
    if (pointsSet.size < 7500) {
      for (let i = 0; i < 10; i++) {
        newPoint({ ctx, coordinates: { x: initialX, y: initialY }, points: pointsSet, sparkles })
        newPoint({ ctx, coordinates: { x: initialX + opts.distance, y: initialY }, points: pointsSet, sparkles })
      }
    } else {
      clearInterval(intervalID)
    }
  }, 1)
  return intervalID
}

// Pattern 6, put even more in the holes.
export function Pattern6(pointsState: any) {
  const sparkles = pointsState.sparkles
  const pointsSet = pointsState.pointsSet
  const ctx = pointsState.ctx
  const rect = ctx.canvas.getBoundingClientRect()
  const width = rect.width
  const height = rect.height

  const initialX = width / 2
  const initialY = height / 2.5

  // This actually continue running interval even during other Modes, however those have more than 7.5k...
  // TODO: Fix this bug. Stop adding new points if not this mode.
  const intervalID = setInterval(function () {
    if (pointsSet.size < 10000) {
      for (let i = 0; i < 10; i++) {
        newPoint({ ctx, coordinates: { x: initialX, y: initialY }, points: pointsSet, sparkles })
        newPoint({ ctx, coordinates: { x: initialX + opts.distance, y: initialY }, points: pointsSet, sparkles })
        newPoint({ ctx, coordinates: { x: initialX + opts.distance * 3 / 4, y: initialY + opts.distance / 4 * Math.sqrt(3) }, points: pointsSet, sparkles })
        newPoint({ ctx, coordinates: { x: initialX + opts.distance * 3 / 4, y: initialY - opts.distance / 4 * Math.sqrt(3) }, points: pointsSet, sparkles })

        newPoint({ ctx, coordinates: { x: initialX + 1.5 * opts.distance, y: initialY }, points: pointsSet, sparkles })
      }
    } else {
      clearInterval(intervalID)
    }
  }, 1)
  return intervalID
}
