import { createTheme } from "@mui/material/styles"
import backgroundTile from "./assets/tiling/tile1.jpg"

// TODO: Make an actually nice color palette.
declare module '@mui/material/styles' {

  // Change type to allow configuring Theme itself.
  export interface Theme {
    brightMode: boolean
    tilingImage: tilingOptions
  }
  interface ThemeOptions {
    brightMode: boolean
    tilingImage: tilingOptions
  }
  interface tilingOptions {
    lightMode: string
    darkMode: string
  }

  interface Palette {
    brightModeText: string
    decoration: decorationOptions
  }
  interface PaletteOptions {
    brightModeText: string
    decoration: decorationOptions
  }

  interface decorationOptions {
    bright: string
    dark: string
  }

  interface BreakpointOverrides {
    xs: false // removes the `xs` breakpoint
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true // adds the `mobile` breakpoint
    tablet: true
    laptop: true
    desktop: true
    large: true
    xlarge: true
  }
}

const breakpoints = {
  values: {
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
    large: 1440,
    xlarge: 1800
  }
}

// TODO: Update theme to be resizeable.
//    Font size for title.
//    Size of Icons.
//    Size of top nav bar.
//    Dark Mode and Bright Mode.
const theme = createTheme({
  tilingImage: {
    lightMode: "", // TODO: Find smth
    darkMode: backgroundTile
  },

  // TODO: Update Canvas to support Bright Mode.
  brightMode: false,
  palette: {
    brightModeText: "#FFFFFF",
    decoration: {
      bright: "#00BBDB",
      dark: ""
    },
    background: {
      paper: '#fff'
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A'
    },
    action: {
      active: '#001E3C'
    },
    success: {
      main: "#eab676",
      dark: '#009688'
    }
  },
  breakpoints
})

export default theme
