import React from "react"
import { Box } from "@mui/material"
import { FullScreenCanvas } from "./Canvas"

export default function LandingPage() {
  return (
    <Box className="Website" display={"flex"} flexDirection={"column"}>
      <FullScreenCanvas />
      {/* Carousel of Occupation, Achievments, Projects stuff */}
    </Box>
  )
}
