import React from "react"
import { Box } from "@mui/material"
import error404 from "./assets/error404.png"

export default function ErrorPage() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <img alt="Error 404." src={error404} width={"50%"} />
    </Box>
  )
}
