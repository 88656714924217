import VCICSquare from "../assets/images/achievements/VCICSquare.png"
import DECASquare from "../assets/images/achievements/DECASquare.png"
import ProblemPitchSquare from "../assets/images/achievements/ProblemPitchSquare.png"
import FraserHacksSquare from "../assets/images/achievements/FraserHacksSquare.png"

export const achievements = [{
  ContentTitle: "VCIC",
  SubTitle: "National First Place Team",
  ContentIntro: `VCIC is the world's largest venture capital competition. My team, representing the University of Waterloo, won 1st place in Canada and went on to compete at the international level.`,
  ContentText: ` VCIC is a competition where teams form a venture capital investment team and participate in a faux venture capital investment process involving real start-ups. After assessing the companies presented, the teams prepare a pitch to their General Partners; real venture capitalists, and are assessed on their skills.`,
  ContentImage: VCICSquare
},
{
  ContentTitle: "DECA",
  SubTitle: "International First Place",
  ContentIntro: `DECA is a highschool and university business competition with over 220,000 members around the globe. I competed in DECA and won first place internationally for the Accounting Applications Series in 2019.`,
  ContentText: ` DECA is a case competiton with a written exam portion and a case study portion. DECA is split into series which define the business related content being assessed.`,
  ContentImage: DECASquare
},
{
  ContentTitle: "Quantum Valley Investments Problem Pitch Competition",
  SubTitle: "Finalist Team",
  ContentIntro: `Teams of four research an industry problem of their choosing, in preparation for innovation. We placed as a finalist team investigating Lunar Dust.`,
  ContentText: ` The objective of this competition was to complete thorough research into a problem, with winners receiving a cash grant to complete further R&D.`,
  ContentImage: ProblemPitchSquare
},
{
  ContentTitle: "FraserHacks Hackathon",
  SubTitle: "Second Place Team",
  ContentIntro: `Our team came second place for the creation of an AI based image recognition app capable of identifying certain items and their nutrition information.`,
  ContentText: ` We primarily trained on the juiceboxes and snacks provided at the competition, and developed this product in under 8 hours. After presenting our group's creation, we placed 2nd and received some prizes.`,
  ContentImage: FraserHacksSquare
}
]
