import React, { useMemo } from "react"
import { Box, Typography } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { NavBarHeight, ContentSectionHeight, BottomCirclesHeight, MinimumHeight } from "./Constants"

// TODO: Types. Can this be done better?
export const ContentBox = (props: any) => {
  const config = props.props.content
  const configLength = props.props.length
  const index: number = props.props.index
  const isMobile = props.props.isMobile
  const theme = useTheme()

  const image = useMemo(() => (<img
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    alt={`Image of ${config.ContentTitle}`}
    src={config.ContentImage}
    style={{
      overflow: "hidden",
      padding: "1em",
      flex: 1,
      objectFit: "contain"
    }}
  />), [config.ContentImage, config.ContentTitle])

  const showImage: boolean = (
    (window.innerWidth > 650) ||
    (window.innerWidth > 600 && config.ContentTitle !== "Quantum Valley Investments Problem Pitch Competition") ||
    (isMobile)
  )

  // Main explanation found in Contents page.
  // Notably, rather than 100% vh, we use 90%vh for this, since we have a 10% buffer for displayed content.
  const MobileHeight = `calc(max(100vh, ${MinimumHeight}px) - ${NavBarHeight}px - 6em - ${4 * ContentSectionHeight}px - ${BottomCirclesHeight}px - 4px)`
  const DesktopHeight = `calc(max(90vh, ${MinimumHeight * 0.9}px) - ${NavBarHeight}px - 6em - ${ContentSectionHeight}px - ${BottomCirclesHeight}px)`

  return (
    // For object fit to work, parent needs defined height.
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: isMobile === true ? MobileHeight : DesktopHeight
      }}
    >
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        height: isMobile === true ? MobileHeight : DesktopHeight
      }}
      >
        <Box sx={{ padding: "1em", paddingBottom: "0" }}>
          <Box sx={{ display: "flex", marginBottom: "1em" }}>
            <Box sx={{ justifySelf: "flex-start", flex: 1 }}>
              <Typography
                sx={{
                  textAlign: "center",
                  textDecoration: "underline",
                  textDecorationColor: theme.palette.decoration.bright,
                  typography: {
                    tablet: "h4",
                    mobile: "h5"
                  }
                }}>
                {config.ContentTitle}
              </Typography>
              {config.SubTitle !== null && (
                <Typography
                  sx={{
                    textAlign: "center",
                    typography: {
                      tablet: "h6",
                      mobile: "Subtitle1"
                    }
                  }}>{config.SubTitle}</Typography>
              )}
            </Box>
            <Box sx={{}}>
              <Typography >
                {index + 1}/{configLength}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            margin: "1em",
            display: "flex",
            marginBottom: "0em",
            alignItems: "flex-end"
          }}>
            {/* TODO: Potential Idea where onHover the long text pops up over the display box. Or on click for mobile. Similar to profile images done at DeepBreathe website. */}
            <Typography variant="body2" sx={{
              typography: {
                tablet: { fontSize: "x-small" },
                laptop: { fontSize: "small" },
                desktop: { fontSize: "medium" }
              }
            }}>
              {config.ContentIntro}
              {(isMobile !== true) && (window.innerWidth > 750) && (<><br /><br /></>)}
              {(isMobile !== true) && (window.innerWidth > 750) && (config.ContentText)}
            </Typography>
          </Box>
        </Box>

        {showImage && image}
      </Box>
    </Box>
  )
}
