import React from "react"
import { Route } from "react-router"
import LandingPage from "./LandingPage"
import ResumePage from "./ResumePage"
import ErrorPage from "./ErrorPage"
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements
} from "react-router-dom"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<LandingPage />}></Route>
      <Route path="resume" element={<ResumePage />}></Route>
      <Route path="*" element={<ErrorPage />} />
    </Route>

  )
)

export default function App(): JSX.Element {
  return (
    <RouterProvider router={router} />
  )
}
