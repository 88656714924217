import DeepBreatheSquare from "../assets/images/jobs/DeepBreatheSquare.png"
import SuperLong from "../assets/images/jobs/SuperLong.png"
import SurfboardLong from "../assets/images/jobs/SurfboardLong.png"
import MarketBoxLong from "../assets/images/jobs/MarketBoxLong.png"
import BalyasnyAssetManagementSquare from "../assets/images/jobs/BalyasnyAssetManagementSquare.png"
import BoostedSquare from "../assets/images/jobs/BoostedSquare.png"

export const jobs = [
  {
    ContentTitle: "Balyasny Asset Management",
    SubTitle: "Infrastructure DevOps - Current",
    ContentIntro: "Global multi-strategy hedge fund with over $21 billion in Assets Under Management (AUM). At BAM, I am working with Terraform, Kubernetes, Helm, AWS, Jenkins, Octopus, and low level scripting languages such as Bash.",
    ContentText: " I am working primarily in a Linux environment (some Windows) and primarily on the Cloud (some on-premise). At the time of writing this, I have very recently begun this new position and have to date updated deployment scripts, configured GitHub Organization Rulesets  and configured a template repository for deploying AWS Lambda SAM for Python with integrations to Vault and more.",
    ContentImage: BalyasnyAssetManagementSquare
  },
  {
    ContentTitle: "Boosted.ai",
    SubTitle: "Software Engineer",
    ContentIntro: "Fintech company developing data-driven AI quantitative analysis software for hedge funds and investment managers. At Boosted.ai, I primarily worked in Python, JavaScript, React, and Email on Acid.",
    ContentText: " At Boosted, I undertook a company-wide overhaul of emails, from the development and testing process to the company wide style used in automated client emails. I added new functionalities such as Earnings Call emails, which provide clients with a summarization of a company's earnings call within an hour; and Discover notifications, which notifies clients to changes in their criteria-based list of investment opportunities.",
    ContentImage: BoostedSquare
  },
  {
    ContentTitle: "Deep Breathe",
    SubTitle: "Software Engineer",
    ContentIntro: `Healthtech Startup leveraging AI to aid diagnostics for lung ultrasound in real time critical care environments. At Deep Breathe I am working with Python, JavaScript, Terraform, Azure and MySQL.`,
    ContentText: ` My primary contributions have been with respect to our cloud infrastructure, data pipeline and database administration. I've migrated our Infrastructure to be managed with Terraform; supported business needs through Infrastructure; deployed & helped develop our public website; revived, refactored and improved our data pipeline; and implemented numerous fixes and features to improve data usability - ultimately increasing the potency of our Machine Learning capabilities.
  `,
    ContentImage: DeepBreatheSquare
  },
  {
    ContentTitle: "Super",
    SubTitle: "Infrastructure DevOps Engineer",
    ContentIntro: `Canadian Unicorn startup named the 5th Fastest Growing Company in North America by Deloitte - involved in fintech, travel and commerce. At Super I worked primarily with Terraform, Python, Kubernetes and Helm.`,
    ContentText: ` One of my projects was a standing microservice called Rosetta, which automatically converted internal user information across our external applications - Slack, Okta, GitLab, OpsGenie, OpsLevel & Email. This unblocked numerous projects and as a side feature was able to reduce costs by over $10,000 annually. I received a $1000 bonus from Super for exceeding expectations in my work.
  In addition, I worked extensively on maintaining our infrastructure & monitoring systems, including updating logging capabilities and serving as one of 10 company on-callers selected from over 200 full time employees.
  `,
    ContentImage: SuperLong
  },
  {
    ContentTitle: "Surfboard",
    SubTitle: "Full Stack Engineer",
    ContentIntro: `San Francisco based start-up revolutionizing board governance for startups. At Surfboard I worked with React and TypeScript on the front end, TypeScript on the back end, and AWS Portal for our infrastructure.`,
    ContentText: ` On the front end, I led the redesign and refactor for Board Approvals - a service we offered to help our clients manage approvals from their board of directors. I additionally led an effort to ensure global design consistency and aided the team with miscellaneous fixes. 
  On the backend I supported features such as approval signatures and time zone management support, while on infrastructure I integrated Google OAuth federated login with our existing Cognito User Pools and fixed email deliverability issues. 
  `,
    ContentImage: SurfboardLong
  },
  {
    ContentTitle: "MarketBox",
    SubTitle: "Full Stack Engineer",
    ContentIntro: `Toronto based start-up connecting consumers to services, from private lessons to household maintenance. At MarketBox I worked with React and JavaScript.`,
    ContentText: ` One of my big wins was the implementation of a new client set up flow that integrated with our existing customer onboarding. Previously, our CEO was onboarding clients with real-time demos and was manually performing the setup for client accounts. This new feature enabled the automation of this process - saving time and increasing our managable sales funnel size.
  I also improved the consumer facing appointment creation flow that is core to our product offering by implementing features such as stateful navigation and fixing bugs to improve the user experience. 
  I further took ownership over the continued development, documentation and updating of our automated email notifications and became the go-to contact for related changes.
  `,
    ContentImage: MarketBoxLong
  }
]
