import React, { forwardRef } from "react"
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const TitleStyleCSS = ({ theme }: { theme: any }) => ({
  textDecoration: "underline",
  textDecorationColor: theme.palette.decoration.bright,
  textDecorationThickness: "0.05em",
  // TODO: Better font size management... Use Theme for this? Change based on page size?
  fontSize: "5vw"
})

// Components
export const StyledTitle = styled(Typography)(TitleStyleCSS)

export const DanielTitleWithRef = forwardRef(function InputFunction(props: any, ref: any) {
  const theme = useTheme()
  return (
    <StyledTitle ref={ref} theme={theme} sx={{ userSelect: "none" }}>
      Daniel Brestoiu
    </StyledTitle>
  )
})

export const DanielTitleNoRef = ({ theme }: { theme: any }) => {
  return (
    <StyledTitle theme={theme} sx={{
      userSelect: "none",
      color: theme.palette.brightModeText,
      alignSelf: "center"
    }}>
      Daniel Brestoiu
    </StyledTitle>
  )
}
