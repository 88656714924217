import React, { useContext, useState } from "react"
import { Box, Typography, Slider, Input, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material"
import { PointContext } from "../Context"
import { Pattern1, Pattern2, Pattern3, Pattern4, Pattern5, Pattern6 } from "../patterns/Patterns"

const MAX = 100
const MIN = 0
const STEP = 10

export function PopperContents(props: any) {
  const pointContext = useContext(PointContext)
  const [value, setValue] = useState<number>(pointContext != null ? pointContext.pointsState.pointsPerClick : 1)
  const [sparkles, setSparkles] = useState<boolean>(pointContext != null ? pointContext.pointsState.sparkles : true)
  const [intervalID, setIntervalID] = useState<any>(null)
  const patternValue = props.patternValue
  const setPatternValue = props.setPatternValue

  // Pattern value resets to 4 when re-opening.

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      newValue = newValue[0]
    }
    setValue(newValue)

    if (pointContext != null) {
      pointContext.pointsState.pointsPerClick = newValue
      pointContext.setPointsState(pointContext.pointsState)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pointsAmount = event.target.value === "" ? MIN : Math.min(Number(event.target.value), MAX)
    console.log("Setting amount of points to ", pointsAmount)
    setValue(pointsAmount)

    if (pointContext != null) {
      pointContext.pointsState.pointsPerClick = pointsAmount
      pointContext.setPointsState(pointContext.pointsState)
    }
  }

  const handleBlur = () => {
    if (value < MIN) {
      setValue(MIN)
    } else if (value > MAX) {
      setValue(MAX)
    }
  }

  const handleSparkles = (change: any) => {
    setSparkles(change)
    if (pointContext != null) {
      pointContext.pointsState.sparkles = change
      pointContext.setPointsState(pointContext.pointsState)
    }
  }

  const clearPoints = () => {
    if (pointContext != null) {
      pointContext.pointsState.pointsSet.clear()
      pointContext.setPointsState(pointContext.pointsState)
    }
    if (intervalID != null) {
      clearInterval(intervalID)
      setIntervalID(null)
    }
  }

  const handlePatternChange = (pattern: String) => {
    setPatternValue(pattern)
    clearPoints()

    // If pattern is one, just clear, no drawing.
    if (pattern === "1") {
      Pattern1(pointContext?.pointsState)
    } else if (pattern === "2") {
      Pattern2(pointContext?.pointsState)
    } else if (pattern === "3") {
      Pattern3(pointContext?.pointsState)
    } else if (pattern === "4") {
      const interval = Pattern4(pointContext?.pointsState)
      setIntervalID(interval)
    } else if (pattern === "5") {
      const interval = Pattern5(pointContext?.pointsState)
      setIntervalID(interval)
    } else if (pattern === "6") {
      const interval = Pattern6(pointContext?.pointsState)
      setIntervalID(interval)
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "1em" }}>
      <Typography variant="h5">Point Pattern Controls</Typography>

      <Typography sx={{ textAlign: "center", paddingRight: "1em" }}>
        NOTE: Click Icon again to close.
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={{ textAlign: "center", paddingRight: "1em" }}>
          Points Per Click:
        </Typography>
        <Box sx={{ display: "flex", flex: 1 }}></Box>
        <Box >
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: STEP,
              min: MIN,
              max: MAX,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
        </Box>
      </Box>
      <Box sx={{ paddingBottom: "1em" }}>
        <Slider
          value={typeof value === 'number' ? value : 0}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          step={STEP}
          min={MIN}
          max={MAX}
        />
      </Box>

      <FormControl sx={{ paddingBottom: "1em", alignItems: "center" }}>
        <FormLabel>Pre-Designed Pattern</FormLabel>
        <RadioGroup
          defaultValue={patternValue}
          sx={{
            alignItems: "center"
          }}
          onChange={(e) => { handlePatternChange(e.target.value) }}
        >
          <FormControlLabel value={"1"} control={<Radio />} label="Pattern 1" />
          <FormControlLabel value={"2"} control={<Radio />} label="Pattern 2" />
          <FormControlLabel value={"3"} control={<Radio />} label="Pattern 3" />
          <FormControlLabel value={"4"} control={<Radio />} label="Pattern 4" />
          <FormControlLabel value={"5"} control={<Radio />} label="Pattern 5" />
          <FormControlLabel value={"6"} control={<Radio />} label="Pattern 6" />
        </RadioGroup>
      </FormControl>

      <FormControl sx={{ paddingBottom: "1em", alignItems: "center" }}>
        <FormLabel>Points Sparkle?</FormLabel>
        <RadioGroup
          row
          value={sparkles}
          onChange={(e) => handleSparkles(e.currentTarget.value)}
          sx={{
            alignItems: "center"
          }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <Button
        aria-label="Clear points."
        variant="contained" onClick={clearPoints}>Clear Points</Button>
    </Box >
  )
}
