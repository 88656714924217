import React from "react"
import { Box, IconButton } from "@mui/material"
import { Link } from "react-router-dom"
import { StyledResumeIcon, StyledLinkedInIcon, StyledGitHubIcon, StyledEmailIcon } from "../styles/Icons"

interface ContactIconsProps {
  row: boolean
}

export const ContactIcons = (props: ContactIconsProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: props.row ? "row" : "column", justifyContent: "center" }}>
      <Box>
        <IconButton
          aria-label="See Daniel Brestoiu's Resume"
          component={Link}
          to="/resume"
        >
          <StyledResumeIcon />
        </IconButton>

        <IconButton
          aria-label="See Daniel Brestoiu's Linked In"
          onClick={(e) => {
            e.stopPropagation()
            window.open('https://www.linkedin.com/in/daniel-brestoiu/', '_blank')
          }}
        >
          <StyledLinkedInIcon />
        </IconButton>
      </Box>

      <Box>

        <IconButton
          aria-label="See Daniel Brestoiu's GitHub"
          onClick={(e) => {
            e.stopPropagation()
            window.open("https://github.com/Daniel-Brestoiu", '_blank')
          }}
        >
          <StyledGitHubIcon />
        </IconButton>

        <IconButton
          aria-label="Email Daniel Brestoiu"
          onClick={(e) => {
            e.stopPropagation()
            window.open("mailto:daniel.brestoiu@uwaterloo.ca", '_blank')
          }}
        >
          <StyledEmailIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
