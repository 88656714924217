import React from "react"
import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material"
import { StyledCloseIcon } from "./styles/Icons"

export default function SettingsDialog(state: any) {
  const show = state.show
  const selfClose = state.selfClose

  return (
    <Dialog open={show} onClose={selfClose}>
      <DialogTitle>
        <h2>Dialog...</h2>
        <IconButton onClick={selfClose}>
          <StyledCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <span>Dialog Content</span>
      </DialogContent>
    </Dialog >
  )
}
