import { NavBarHeight } from "./Constants"

export function isScrolledIntoView(el: HTMLElement | null) {
  if (el == null) {
    return false
  }
  const rect = el.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom

  // Only completely visible elements return true:
  // const isVisible = (elemTop - NAVBARHEIGHT >= 0) && (elemBottom <= window.innerHeight)
  // Partially visible elements return true:
  const isVisible = elemTop < window.innerHeight && elemBottom - NavBarHeight >= 0

  // TODO: Update back to being - Nav Bar Height, but update logic for showing Normal Title to be based on scroll position.
  // TODO: Get the 80px navbar to be set to a constant, and make it updatable, smaller for smaller widths.
  return isVisible
}
