import type { Coordinates } from "./Canvas"
import React, { useEffect } from "react"

// TODO: General settings sticky at the top.
// TODO: Arrow to click to go down at the bottom (not global sticky)

// TODO: Fix logic on click, fix speed, etc. Save mode as "Simple Mode"
// TODO: Add Hexagon Mode
// TODO: Add Spam Mode (hella points, cool outline.)

// TODO: Look into clicking random positions every 5 seconds.
// TODO: Look into having different modes & a settings button to control it.

export const baseRad = Math.PI / 3

interface newPointProps {
  ctx: CanvasRenderingContext2D
  coordinates: Coordinates
  points: Set<Points>
  sparkles: boolean
}

export const opts = {
  distance: 50,
  baseTime: 75,
  color: 'hsl(hue,100%,light%)',
  baseLight: 50,
  addedLight: 10, // [50-10,50+10]
  shadowToTimePropMult: 3,
  baseLightInputMultiplier: 0.01,
  addedLightInputMultiplier: 0.02,

  repaintAlpha: 0.05,
  hueChange: 1,

  spawnChance: 1,
  sparkChance: 0.05,
  sparkDist: 10,
  sparkSize: 1,

  remainingProgeny: 5
}

// Custom hook, useAnimation which handles the animation loop.
export const useAnimation = (props: loopProps) => {
  const canvasRef = props.canvasRef
  const width = props.width
  const height = props.height
  const points = props.points
  const tickRef = props.tickRef
  const sparkles = props.sparkles

  useEffect(() => {
    // Assigning tickRef.current isn't actually necessary, since its value will be that of the most recent
    // assignment at the time of the clean up of this useEffect, but is left to avoid Lint Error :)
    tickRef.current = window.requestAnimationFrame(() => loop({ canvasRef, width, height, points, tickRef, sparkles }))
    return () => window.cancelAnimationFrame(tickRef.current)
  })
}

export interface loopProps {
  canvasRef: React.RefObject<HTMLCanvasElement>
  width: number
  height: number
  points: Set<Points>
  tickRef: React.MutableRefObject<number>
  sparkles: boolean
}

/** Function handling the looping of animation. */
export function loop(props: loopProps) {
  const width = props.width
  const height = props.height
  const points = props.points
  const canvasRef = props.canvasRef
  const sparkles = props.sparkles

  const tickRef = props.tickRef
  let tick = tickRef.current

  const canvas = canvasRef.current
  const ctx = canvas?.getContext('2d')
  if ((ctx != null)) {
    ctx.beginPath()

    // Rewrite black base.
    ctx.globalCompositeOperation = 'source-over' // Write over everything.
    ctx.shadowBlur = 0
    ctx.fillStyle = 'rgba(0,0,0,alp)'.replace('alp', opts.repaintAlpha.toString())
    ctx.fillRect(0, 0, width, height)
    ctx.globalCompositeOperation = 'lighter' // Enable future changes.

    // Determine color for points now.
    const lightInputMultiplier = opts.baseLightInputMultiplier + opts.addedLightInputMultiplier * Math.random()
    const newLight = (opts.baseLight + opts.addedLight * Math.sin(tick * lightInputMultiplier))

    const color = 'hsl(hue,100%,light%)'.replace('light', newLight.toString()).replace('hue', (tick * opts.hueChange).toString())
    ctx.shadowColor = ctx.fillStyle = color
    ctx.shadowBlur = 2 + Math.sin(tick) // (Ranges from 1 to 3 now.)

    // Draw animation for each point.
    const pointsArr = Array.from(points.values())
    for (const point of pointsArr) {
      point.draw(sparkles)
    }
    ctx.stroke()

    tick++
    if (tick >= (360 / opts.hueChange)) {
      tick = 0
    }
  }
  tickRef.current = window.requestAnimationFrame(() => loop({ canvasRef, width, height, points, tickRef, sparkles }))
}

/** Add a new point, given a Canvas context to draw on (ctx), coordinates to draw on,
 * and a set of points to add this point to, for tracking. */
export function newPoint(props: newPointProps) {
  if (props.coordinates !== undefined) {
    const newPoint = new Points(50, props.coordinates, props.ctx, props.points)
    newPoint.draw(props.sparkles)
    props.points.add(newPoint)
  }
}

export class Points {
  remainingProgeny: number
  spawnLoc: Coordinates
  directionRads: number
  ctx: any
  points: Set<Points>

  cumulativeTime: number = 0
  time: number = (opts.baseTime)
  targetTime: number = (opts.baseTime)
  addedX: number = 0
  addedY: number = 0
  added_coordinates: Coordinates = { x: 0, y: 0 }

  constructor(remainingProgeny: number, spawnLoc: Coordinates, ctx: any, points: Set<Points>, directionRads?: number | undefined) {
    this.remainingProgeny = remainingProgeny
    this.spawnLoc = spawnLoc
    this.ctx = ctx
    this.points = points

    // Options are 0, Math.PI * 4/3 and Math.PI * 2/3.
    // First, 1/3 chance its option one. Then, 50% chance its option 2 and not 3.
    this.directionRads = directionRads ??
      (Math.random() < 1 / 3
        ? (0)
        : (Math.random() < 0.5 ? Math.PI * 4 / 3 : Math.PI * 2 / 3))
  }

  turn() {
    this.added_coordinates.x += this.addedX
    this.added_coordinates.y += this.addedY
    this.time = 0

    this.directionRads += baseRad * (Math.random() < 0.5 ? 1 : -1)

    this.addedX = Math.cos(this.directionRads)
    this.addedY = Math.sin(this.directionRads)

    // Logic to die:
    // if (Math.random() < opts.dieChance || this.x > dieX || this.x < -dieX || this.y > dieY || this.y < -dieY) { this.reset()
  }

  draw(sparkles: boolean) {
    this.time += 1
    this.cumulativeTime += 1

    if (this.time >= this.targetTime) {
      this.turn()
    }

    const position = this.time / this.targetTime
    const wave = Math.sin(position * Math.PI / 2)
    const x = this.addedX * wave
    const y = this.addedY * wave

    // Queue actual point to be draw.
    const xPos = this.spawnLoc.x + (this.added_coordinates.x + x) * opts.distance
    const yPos = this.spawnLoc.y + (this.added_coordinates.y + y) * opts.distance
    this.ctx.rect(xPos, yPos, 2, 2)

    // Queue sparks around this point. For some reason, always giving true. Need better typing?
    // TODO: Come back to this later. Verify its because of being untyped.
    if (String(sparkles) === "true") {
      if (Math.random() < opts.sparkChance) {
        this.ctx.rect(
          xPos + Math.random() * opts.sparkDist * (Math.random() < 0.5 ? 1 : -1) - opts.sparkSize / 2,
          yPos + Math.random() * opts.sparkDist * (Math.random() < 0.5 ? 1 : -1) - opts.sparkSize / 2,
          opts.sparkSize, opts.sparkSize
        )
      }
    }
  }

  unspawnSelf() {
  }
}
